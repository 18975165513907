import i18n from "i18next";
import { initReactI18next } from "react-i18next";


//Qui gestiamo le traduzioni delle label in base alla lingua selezionata 

i18n.use(initReactI18next).init({
    lng: "it",
    fallbackLng: "it",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                lingua: "en",
                //profile && general
                labelProfile1: "Company Profile",
                labelProfile2: "Fiscal year",
                labelProfile3: "Cluster",
                labelProfile4: "Revenue (last FY €)",
                labelProfile5: "Number of employees",
                labelProfile6: "Business name",
                gas:"gas",
                emission: "Emission",
                consumption: "Consumption",
                percentage: "Percentage",
                spending:"Spending",
                emissions: "Emissions",
                option: "Option",
                continue: "CONTINUE",
                start: "start",
                back: "BACK",
                fuel: "Fuel",
                value: "Value",
                unit: "unit",
                yes: "YES",
                enter:"Enter",
                nonPertinente: "NOT RELEVANT",
                country: "Country",
                //stepper
                stepperLabel1: "Scope 1 Stationary",
                stepperLabel2: "Scope 1 Mobile",
                stepperLabel3: "Scope 1 Industrial gases",
                stepperLabel4: "Scope 1 Refrigerant gases",
                stepperLabel5: "Scope 2 Electricity",
                stepperLabel6: "Scope 2 Heat/cooling",
                stepperLabel7: "Corporate carbon footprint",
               
                scopeTitle1: "Stationary emissions",
                scopeTitle2: "Mobile emissions",
                scopeTitle3: "Industrial gases emissions",
                scopeTitle4: "Refrigerant gases emissions",
                scopeTitle5: "Electricity emissions",
                scopeTitle6: "Heat/Cooling emissions",
                

                infoGreen: "The response is not accounted in the emission results, but is useful to track best practice already implemented by the company",

                //settore
                questionSettore: "What subgroup does the company belong to?",
                sector: "subgroup",
                //scope1                        
                scope1Option1: "Consumption data entry form",
                scope1Option12: "Indicate fuels/gas for which the amount consumed is known (e.g., Liters, MWh, Tons)",
                scope1Option2: "Expense data entry form",
                scope1Option22: "Indicate fuels/gas for which the expenditure is known (€)",
                scope1Option3: "Issue data entry form",
                scope1Option32: "Indicate fuels/gas for which CO2e emissions are known",
                scope1Option4: "Percentage data entry form",
                scope1Option42: "If you do not have information available on all fuels/gas used, you can enter percentages of use",
                //S1
                questionS1: "Do you have information on fuel consumption/expenditure used in the company facilities?",
                questionS12: "Select 'Not relevant' if the requested data refer to activities not carried out by the company",
                questionS13: "eg. no fossil fuels are used in the corporate structures",
                infoS1: "Scope 1 stationary emissions refer to the direct emissions of greenhouse gases from a company's combustion sources. Examples of scope 1 emission sources include the burning of coal, oil or natural gas for energy production, or use in machinery",
                titleS1: "What type of fuel is used in company facilities? (owned, rented)",
                exampleS1: "eg. heating boilers, facility management, site generators, cogenerators",
                //M1
                questionM1: "Do you have information relating to consumption/expenditure on the fuel used in company vehicles?",
                questionM12: "Selezionare 'Non pertinente' qualora i dati richiesti facciano riferimento ad attività non effettuate dall'azienda",
                questionM13: "eg. no fossil fuels are used in the company vehicles",
                questionM14: "Are zero-emission electric vehicles used in the company (leased, owned)?",
                infoM1: "Scope 1 mobile emissions refer to direct greenhouse gas emissions from mobile sources, such as vehicles, machinery and equipment used by a business. These emissions are generated by the consumption of fossil fuels or other energy sources in the engines of vehicles and machinery.",
                titleM1: "What type of fuel is used by the machinery/company vehicles/leasing?",
                exampleM1: "Both owned and leased vehicles must be considered (e.g. light vehicles, heavy trucks, bulldozers, tractors, excavators, etc.)",
                //F1
                questionF1: "Do you have information on the quantities of industrial gases (e.g. welding, fire-fighting) used?",
                questionF12: "Select 'Not relevant' if the requested data refer to activities not carried out by the company",
                questionF13: "eg. no industrial gases are used in the company facilities",
                infoF1: "Scope 1 emissions due to the use of industrial gases are categorized as direct emissions of greenhouse gases, resulting from the loss or use of industrial gases such as welding and fire-fighting gases.",
                titleF1: "What type of industrial gas is used in the facilities?",
                exampleF1: "Only consider industrial gases used in owned and leased facilities, not those used by subcontractors",
                //R1
                questionR1: "Do you have information on the quantities of refrigerant gas refills (e.g. for air conditioners) used?",
                questionR12: "Select 'Not relevant' if the requested data refer to activities not carried out by the company",
                questionR13: "eg. no refrigerant gases are used",
                infoR1: "The emissions deriving from the use of refrigerant gases are the consequence of the evaporation and dispersion of the gases used for the cooling of systems and equipment, such as air conditioners and refrigerators and are considered direct emissions (scope 1). These gases, known as hydrofluorocarbons (HFCs), chlorofluorocarbons (CFCs), and perfluorocarbons (PFCs), are considered greenhouse gases due to their ability to trap heat in the atmosphere. In particular, refrigerant gases are known to contribute significantly to global warming and the deterioration of the ozone layer.",
                titleR1: "Indicate the quantities of refrigerant gas for air conditioning systems",
                //scope2
                scope2Option1: "Consumption data entry form",
                scope2Option12: "Indicate the amount of electricity purchased and the country",
                scope2Option2: "Expenditure data entry form",
                scope2Option22: "Indicate the expenditure incurred on electricity purchased from the grid and the country",
                scope2Option3: "Issue data entry form",
                scope2Option32: "If available, enter supply contract-specific emission factors (kgCO2e/kWh) and corresponding purchased electricity quantities not included in the consumption and/or expenditure data sheets on the tab below",
                //E1
                questionE1: "Do you have information about purchasing electricity from the grid?",
                questionE12: "Select 'Not relevant' if the requested data refer to activities not carried out by the company",
                questionE13: "eg. no electricity is purchased in the corporate structures",
                infoE1: "Scope 2 emissions refer to greenhouse gas emissions produced indirectly by an organization due to the production of electricity, purchased and consumed by the organization. These emissions can be the subject of the purchase of renewable energy certificates (guarantee of origin) that attest to the source",
                titleE1: "Is grid-supplied electricity consumed in facilities without guarantees of origin?",
                title2E1: "Electricity supplied from the grid with guarantees of origin(green energy) is consumed in the facilities?",
                exampleE1: "Indicate the detail of the information available",
                //E12
                infoE12: "Le emissioni stazionarie scope 1 si riferiscono alle emissioni dirette di gas serra prodotte da fonti di combustione  di un'azienda. Esempi di fonti di emissioni scope 1 includono la combustione di carbone, petrolio o gas naturale per la produzione di energia, o utilizzo nei macchinari",
                titleE12: "Does the company self-generate energy from renewable sources?",
                exampleE12: "Eg solar panels, wind turbines",
                //E2
                titleE2: "Is heat/cooling purchased for on-site facilities?",
                infoE2: "The purchase of heat or cooling from a third party is classified as a scope 2 emission. When an organization purchases heat/cooling from a thermal power plant or specialty company, it is responsible for the emissions generated during the production of that energy source. This is because the organization contributes to the demand for electricity or heat/cooling, which in turn fuels the energy industry to adopt energy production practices that may be more or less sustainable.",
                heat: "heat",
                cooling: "cooling",
                text1E2: "Indicate quantity heat",
                text2E2: "Indicate quantity cooling",
                //ERRORS
                warning1: "Answer the preliminary question.",
                warning2: "Fill in at least one field",
                warning3: "Enter a numeric data",
                warning4: "The sum of percentages must be less than 100(check input data)",
                warning5: "Fill in the previous lines to add more inputs",
                warning6: "Missing data, check input data",
                warning7: "Fuel/gas already entered",
                warning8: "Country already entered",
                warning9: "Fill in at least one of the above sections.",
                warning10: "Enter a value other than 0 and less than 100.",
                warning11: "Answering questions related to renewable energy",
                warning12: "Compilation error: fill out at least one section of the questionnaire",
                warning13: "Compilation error..",
                warning14: "WARNING you have entered the same fuel/gas multiple times, check for two different emission sources.",
                warning15: "Select a year for report display",
                warning16: "WARNING Not entering the information results in a calculation based on industry averages. It is recommended that related information be sourced to maximize the accuracy of the estimation",
                warning17: "WARNING you have entered the same country multiple times",
                warning18: "Caution: do not enter purchased electricity quantities reported or intended to be reported on consumption and/or expenditure data sheets",

                //CARD
                label1Card: "Welcome to the Open-es Carbon Intelligence Tool",
                label2Card: "The Carbon Intelligence Tool (CIT) will help you calculate your scope 1&2 CO2 emissions. Scope 1 emissions represent the direct emissions generated by your company, and scope 2 emissions represent the emissions generated by the purchase of thermal and cooling energy. The tool calculates scope 1&2 emissions according to the guidelines of the", 
                label3Card: "Guidelines for completing the questionnaire",
                label4Card: "On the following pages you will be guided through the calculation by an intuitive interface that allows you to easily enter the information you have (e.g., electricity consumption). The more timely information you enter, the more accurate the calculation will be. The data entered in the questionnaire are saved, so you can stop filling in and continue later to complete it.",
                label5Card: "The Open-es Carbon Intelligence Tool report",
                label6Card: "When data entry is complete, a report will be generated that will give you the details of your emissions across categories and your ranking against the industry average." ,
                label7Card: "Good Luck!",
                label8Card: "Use the buttons below to download the user manual and data collection form.",
                label9Card: "User manual",
                label10Card: "Data collection form",
                label11Card: '"A Corporate Accounting and Reporting Standard" - Revised Edition, validated by RINA SERVICES S.p.A.',
                help: "Help",

                //ASSISTENZA
                label1Assistenza: "What is the Carbon Intelligence Tool?",
                label2Assistenza: "The Carbon Intelligence Tool (CIT) is designed to guide companies participating in Open-es with the goal of helping them accelerate the sustainable transition by measuring their CO2 emissions (Scope 1&2) and identifying decarbonization levers. The Tool is designed to be a user-friendly tool, rigorous in calculation methodologies and in accordance with ",
                label3Assistenza: '"A Corporate Accounting and Reporting Standard" - Revised Edition, as validated by RINA SERVICES S.p.A.',
                label4Assistenza: "Currently, the Tool's calculation model has been implemented to be able to measure emissions for companies belonging to four scopes, three of which are sectoral and one general. Specifically, the scopes are: 1) Construction and Engineering; 2) Industrial; 3) Transport; 4) Energy and Utilities; 5) Chemical and Pharmaceutical; 6) FMC and Durable goods; 7) Tertiary; 8) Other. The domain belonging is assigned according to the sector declared by the company when it created the Open-es profile. The Carbon Intelligence Tool implemented for the OTHER scope can be used by all companies for which a specific sector scope is not available and allows the company's scope 1&2 emissions to be calculated.",
                label5Assistenza: "How to enter data?",
                label6Assistenza: "Three different questions need to be answered for each emission category:",
                label7Assistenza: " - The specific emission category is part of the company's activities.",
                label8Assistenza: " - Is information available on the specific emission category? If no, the CIT uses industry averages for estimation;",
                label9Assistenza: " - You are asked to enter information on emission sources (i.e. list of fuels/gases, countries where electricity is purchased). It is possible to enter the information in different formats (consumption data, expenditure data, equivalent emissions, percentages) and it is essential that each emissive source is entered only once: for example, if both consumption and expenditure information are available for an emissive source, only the consumption information should be entered. Please consult the User's Manual for further details.",
                label10Assistenza: "Directions on how to use the data collection form.",
                label11Assistenza: "A data collection excel is available for users that traces the structure of the questionnaire. Users can use this file to collect all the necessary information offline, speeding up completion on the portal.",
                label12Assistenza: "Useful links",
				label13Assistenza: "For support contact ",
         
               

                label1Final: "Good job, you have completed the questionnaire!",
                label2Final: "View",
                
                //Report
                label1Report: "EXCELLENT WORK.",
                label2Report: "This is your carbon footprint relative to the",
                label3Report: "Go back to Open-es and you will find the estimation information reported",
                review: "REVIEW",
                label4Report: "ACCURACY LEVEL",
                HIGH: "HIGH",
                HIGHEST: "HIGHEST",
                MEDIUM: "MEDIUM",
                GOOD: "GOOD",
                LOW:"LOW",
                average:"average",
                output:"your company",
                accuracy:"ACCURACY",
                labelHigh: "You have achieved a high level of accuracy through extensive use of consumption data (e.g., liter, kWh) and completion of all relevant questions. We recommend that you keep the quality of the input data high to ensure timely calculation of emissions",
                labelMedium: "You have obtained an average level of accuracy because emissions of some categories were estimated and/or expenditure data were used. We recommend that you include as much consumption data (e.g., liters, kWh) as possible to improve the quality of the input data and thus ensure a more accurate calculation of emissions",
                labelLow: "You achieved a low level of accuracy because data for the main questions were not provided and/or consumption data (e.g., liters, kWh) were not used but expenditure information.We recommend that you provide data for the main questions and include as much consumption data (e.g., liters, kWh) as possible to improve the quality of the input data and thus ensure a more accurate calculation of emissions",
                label5Report: "YOUR CARBON FOOTPRINT",
                label6Report: "Details of scope 1 emissions",
                label62Report: "Details of scope 2 emissions",
                label7Report: "Stationary combustion",
                label72Report: "direct greenhouse gas emissions from fossil fuels in corporate facilities.",
                label8Report: "Mobile combustion",
                label82Report: "direct emissions from company vehicles.",
                label9Report: "Industrial gases",
                label92Report: "direct emissions from industrial processes with specific gases.",
                label10Report: "Refrigerant gases",
                label102Report: "direct emissions from refrigeration systems.",
                label11Report: "Electricity",
                label112Report: "indirect emissions from electricity purchased.",
                label12Report: "Heat/cooling",
                label122Report: "indirect emissions from the purchase of heat or cooling.",
                label13Report: "Carbon intensity per million € of revenue (tCO2eq/M€) and comparison with industry average*.",
                label14Report: "*The sector benchmark is estimated based on statistic data of the sector and doesn't capture company's specific characteristics",
                label19Report: "Accelerate the ecological transition!",
                label20Report: "The proposed decarbonization levers are consistent and applicable for the emissions values that have emerged and the business activities performed. They are therefore to be understood as the priority areas on which to undertake a decarbonization pathway, to be configured and aligned with any priorities already undertaken.",
                label21Report: "To visualize the emission categories detail, please fill out all the sections of the Scope 1 questionnaire and improve the level of accuracy of the result",
                label22Report: "To visualize the emission categories detail, please fill out all the sections of the Scope 2 questionnaire and improve the level of accuracy of the result",
                label23Report: "Every decarbonization lever has a related TRL, which stands for for Technology Readiness Level of the decarbonization action (included in the tool from level 7 Pre-commercial demonstration to level 11 Proof of stability reached), see references to",
                infoScope1ReportLine2: "-Mobiles: direct emissions from company vehicles.",
                infoScope1ReportLine3: "-Industrial gases: direct emissions from industrial processes with specific gases.",
                infoScope1ReportLine4: "-Refrigerant gases: direct emissions from refrigeration systems.",
                infoScope1ReportLine1: "-Stationary: direct greenhouse gas emissions from fossil fuels in business facilities.",
                infoScope2ReportLine1: "-Electricity: indirect emissions from power purchase.",
                infoScope2ReportLine2: "-Heat/cooling: indirect emissions from heat or cooling purchase.",
                openes: "Back to Open es",
                disclaimer: "The “Carbon Intelligence Tool” application is a tool made available by Eni S.p.A. free of charge to allow users to quantify direct (Scope 1) and indirect greenhouse gas emissions from energy purchases (Scope 2). RINA has issued a validation opinion on compliance of the Carbon Intelligence Tool with the GHG PROTOCOL 'A CORPORATE ACCOUNTING AND REPORTING STANDARD' - REVISED EDITION but not on the outputs generated, which depend on the correctness of the data entered. The outputs generated by the application are based on the data entered independently and autonomously by the Users, who take on the related responsibilities. ENI S.p.A. and its suppliers who have worked on the creation of the application do not provide any guarantee, explicit or implicit, with respect to the use of the application and, consequently, of the output generated.",               
            }
        },
        it: {
            translation: {
                lingua: "it",
                //profile
                labelProfile1: "Profilo Aziendale",
                labelProfile2: "Anno fiscale",
                labelProfile3: "Ambito",
                labelProfile4: "Fatturato (ultimo FY €)",
                labelProfile5: "Numero Dipendenti",
                labelProfile6: "Ragione sociale",
                gas:"Gas",
                emission: "Emissione",
                consumption: "Consumo",
                percentage: "Percentuale",
                spending:"Spesa",
                emissions: "Emissioni",
                option: "Opzione",
                continue: "CONTINUA",
                back: "INDIETRO",
                start: "INIZIA",
                fuel: "Combustibile",
                value: "Valore",
                unit: "unita'",
                yes: "SI",
                enter:"Entra",
                nonPertinente: "NON PERTINENTE",
                country: "Paese",
                
                //settore
                questionSettore: "A quale settore di dettaglio appartiene l'azienda?",
                sector: "settore di dettaglio",
                //stepper
                stepperLabel1: "Scope 1 Stazionario​",
                stepperLabel2: "Scope 1 Mobile​",
                stepperLabel3: "Scope 1 Gas industriali​",
                stepperLabel4: "Scope 1 Gas refrigeranti",
                stepperLabel5: "Scope 2 Energia elettrica",
                stepperLabel6: "Scope 2 Calore/raffreddamento",
                stepperLabel7: "Corporate carbon footprint",

                scopeTitle1: "Emissioni stazionarie",
                scopeTitle2: "Emissioni mobili",
                scopeTitle3: "Emissioni gas industriali",
                scopeTitle4: "Emissioni gas refrigeranti",
                scopeTitle5: "Emissioni energia elettrica",
                scopeTitle6: "Emissioni calore/raffreddamento",

                infoGreen: "La risposta non concorre al calcolo delle emissioni, ma permette di tracciare best practice già implementate in azienda",
                //scope1
                scope1Option1: "Scheda di inserimento dati di consumo",
                scope1Option12: "Indicare combustibili/gas per cui si conosce il quantitativo consumato (es. Litri, MWh, Tonnellate)",
                scope1Option2: "Scheda di inserimento dati di spesa",
                scope1Option22: "Indicare combustibili/gas per cui si conosce la spesa (€)",
                scope1Option3: "Scheda di inserimento dati di emissione",
                scope1Option32: "Indicare combustibili/gas per cui si conosce le emissioni di CO2e",
                scope1Option4: "Scheda di inserimento dati di percentuale",
                scope1Option42: "Se non si hanno a disposizione informazioni su tutti i combustibili/gas utilizzati è possibile inserire le percentuali di utilizzo",
                //S1
                questionS1: "Hai informazioni relative ai consumi/spesa per i carburanti utilizzati nelle strutture aziendali?",
                questionS12: "Selezionare 'non pertinente' qualora i dati richiesti facciano riferimento ad attività non effettuate dall'azienda",
                questionS13: "es. nelle strutture aziendali non vengono utilizzati combustibili di origine fossile",
                infoS1: "Le emissioni stazionarie scope 1 si riferiscono alle emissioni dirette di gas serra prodotte da fonti di combustione  di un'azienda. Esempi di fonti di emissioni scope 1 includono la combustione di carbone, petrolio o gas naturale per la produzione di energia, o utilizzo nei macchinari",
                titleS1: "Quale tipo di carburante viene utilizzato nelle strutture aziendali? (di proprietà, in affitto)",
                exampleS1: "es. caldaie per il riscaldamento, facility management, generatori di cantiere, cogeneratori",
                //M1
                questionM1: "Hai informazioni relative ai consumi/spesa per i carburanti utilizzati nei mezzi aziendali?",
                questionM12: "Selezionare 'Non pertinente' qualora i dati richiesti facciano riferimento ad attività non effettuate dall'azienda",
                questionM13: "es. nei mezzi aziendali non vengono utilizzati combustibili di origine fossile",
                questionM14: "In azienda vengono utilizzati veicoli elettrici a zero emissioni (leasing, di proprietà)?",
                infoM1: "Le emissioni scope 1 mobili si riferiscono alle emissioni dirette di gas a effetto serra provenienti da fonti mobili, come ad esempio veicoli, macchinari e attrezzature utilizzate da un'azienda. Queste emissioni sono generate dal consumo di carburante fossile o altre fonti di energia all'interno dei motori di mezzi e macchinari.",
                titleM1: "Quale tipo di carburante viene utilizzato dai macchinari/veicoli aziendali/leasing?",
                exampleM1: "Vanno considerati veicoli di proprietà e quelli in leasing (es. autoveicoli leggeri, autocarri pesanti, ruspe, trattori, escavatori ecc…)",
                //F1
                questionF1: "Hai informazioni in merito ai quantitativi di gas industriali (es. saldatura, antincendio) utilizzati?",
                questionF12: "Selezionare 'Non pertinente' qualora i dati richiesti facciano riferimento ad attività non effettuate dall'azienda",
                questionF13: "es. nelle strutture aziendali non vengono utilizzati gas industriali",
                infoF1: "Le emissioni scope 1 dovute all'utilizzo di gas industriali sono categorizzate come emissioni dirette di gas a effetto serra, derivano dalla perdita o l'utilizzo di gas industriali come gas di saldatura e antincendio.",
                titleF1: "Quale tipo di gas industriale viene utilizzato nelle strutture?",
                exampleF1: "Considerare solamente gas industriali utilizzati nelle strutture  di proprietà e in affitto, non quanto utilizzato da subappaltatori",
                //R1
                questionR1: "Hai informazioni in merito ai quantitativi di ricarica di gas refrigeranti (es. per i condizionatori) utilizzati?",
                questionR12: "Selezionare 'Non pertinente' qualora i dati richiesti facciano riferimento ad attività non effettuate dall'azienda",
                questionR13: "es. non vengono utilizzati gas refrigeranti",
                infoR1: "Le emissioni derivanti dall'utilizzo di gas refrigeranti sono la conseguenza dell'evaporazione e della dispersione dei gas utilizzati per il raffreddamento di sistemi e apparecchiature, come ad esempio condizionatori d'aria e frigoriferi e sono considerate emissioni dirette (scope 1). Questi gas, noti come idrofluorocarburi (HFC), clorofluorocarburi (CFC) e perfluorocarburi (PFC), sono considerati gas ad effetto serra a causa della loro capacità di trattenere il calore nell'atmosfera. In particolare, i gas refrigeranti sono noti per contribuire significativamente al riscaldamento globale e al deterioramento dello strato di ozono.",
                titleR1: "Indicare i quantitativi di gas refrigerante per gli impianti di condizionamento",
                //scope2
                scope2Option1: "Scheda di inserimento dati di consumo",
                scope2Option12: "Indicare il quantitativo di energia elettrica acquistata e il paese",
                scope2Option2: "Scheda di inserimento dati di spesa",
                scope2Option22: "Indicare la spesa sostenuta per l'elettricità acquistata dalla rete e il paese",
                scope2Option3: "Scheda di inserimento dati di emissione",
                scope2Option32: "Se disponibili, inserire nella scheda sottostante i fattori di emissione specifici dei contratti di fornitura (kgCO2e/kWh) e i corrispondenti quantitativi di energia elettrica acquistata non inseriti nelle schede dati di consumo e/o spesa",

                //E1
                questionE1: "Hai informazioni riguardo all'acquisto di energia elettrica dalla rete?",
                questionE12: "Selezionare 'non pertinente' qualora i dati richiesti facciano riferimento ad attività non effettuate dall'azienda",
                questionE13: "es. nelle strutture aziendali non viene acquistata energia elettrica",
                infoE1: "Le emissioni scope 2 si riferiscono alle emissioni di gas a effetto serra prodotte indirettamente da un'organizzazione dovute alla produzione di energia elettrica, acquistata e consumata dall'organizzazione stessa. Queste emissioni possono essere oggetto dell'acquisto di certificati di energia rinnovabile (garanzia d'origine) che attestano la fonte ",
                titleE1: "Nelle strutture viene consumata energia elettrica fornita dalla rete senza garanzie di origine?",
                title2E1: "Nelle strutture viene consumata energia elettrica fornita dalla rete con garanzie di origine(energia verde)?",
                exampleE1: "Indicare il dettaglio delle informazioni disponibili",
                //E12
                infoE12: "Presso l'azienda viene autogenerata energia da fonti rinnovabili?",
                titleE12: "Presso l'azienda viene autogenerata energia da fonti rinnovabili?",
                exampleE12: "Es. pannelli solari, turbine eoliche",
                //E2
                infoE2: "L'acquisto di calore o raffreddamento da una terza parte è classificata come emissione scope 2. Quando un'organizzazione acquista calore/raffreddamento da una centrale termica o un'azienda specializzata, è responsabile delle emissioni generate durante la produzione di quella fonte energetica. Questo perché l'organizzazione contribuisce alla domanda di energia elettrica o di riscaldamento /raffreddamento, che a sua volta alimenta l'industria energetica ad adottare pratiche di produzione energetica che possono essere più o meno sostenibili.",
                titleE2: "Viene acquistato Calore/raffreddamento per le strutture on-site?",
                heat: "calore",
                cooling: "raffreddamento",
                text1E2: "Indicare quantitativo calore",
                text2E2: "Indicare quantitativo raffreddamento",

                //ERRORS
                warning1: "Rispondere alla domanda preliminare",
                warning2: "Compilare almeno un campo",
                warning3: "Inserire un dato numerico",
                warning4: "La somma delle percentuali deve essere minore di 100(controllare i dati inseriti)",
                warning5: "Compilare le precedenti righe per aggiungere altri input",
                warning6: "Dato mancante, controllare i dati inseriti",
                warning7: "Combustibile/gas già inserito",
                warning8: "Paese già inserito",
                warning9: "Compilare almeno una delle sezioni precedenti",
                warning10: "Inserire un valore diverso da 0 e minore di 100",
                warning11: "Rispondere alle domande relative all'energia rinnovabile",
                warning12: "Errore di compilazione: compilare almeno una sezione del questionario",
                warning13: "Errore di compilazione..",
                warning14: "ATTENZIONE hai inserito più volte lo stesso combustibile/gas, controllare che siano due fonti emissive diverse",
                warning15: "Selezionare un anno per la visualizzazione del report",
                warning16: "ATTENZIONE Il mancato inserimento delle informazioni comporta un calcolo dello scope basato su media di settore. Si consiglia di reperire le informazioni relative per massimizzare l' accuratezza della stima",
                warning17: "ATTENZIONE hai inserito più volte la stessa nazione",
                warning18: "Attenzione: non inserire i quantitativi di energia elettrica acquistata riportati o che si intende riportare nelle schede dati di consumo e/o spesa",
                //CARD
                label1Card: "Benvenuto nel Carbon Intelligence Tool di Open-es",
                label2Card: "Il Carbon Intelligence Tool (CIT) ti aiuterà a calcolare le tue emissioni di CO2 scope 1&2. Le emissioni scope 1 rappresentano le emissioni dirette generate dalla tua azienda, e le emissioni scope 2 rappresentano le emissioni generate dall'acquisto di energia termica e frigorifera. Il tool calcola le emissioni di scope 1&2 secondo le linee guida del​", 
                label3Card: "Linee guida per la compilazione del questionario​", 
                label4Card: "Nelle pagine successive verrai accompagnato nel calcolo attraverso un'interfaccia intuitiva che ti permetterà di inserire facilmente le informazioni di cui disponi (ad esempio i consumi di energia elettrica). Più informazioni puntuali inserisci, maggiore sarà l'accuratezza del calcolo. I dati inseriti nel questionario vengono salvati, per cui puoi interropere la compilazione e continuare successivamente al suo completamento.​", 
                label5Card: "Il report del Carbon Intelligence Tool di Open-es​​",
                label6Card: "Al termine dell'inserimento dei dati verrà generato un report che ti darà il dettaglio delle emissioni tra le diverse categorie e il tuo posizionamento rispetto alla media di settore.", 
                label7Card: "Buon lavoro!",
                label8Card: "Usa i bottoni sottostanti per scaricare il manuale utente ed il form per la raccolta dei dati​",
                label9Card: "Manuale utente​",
                label10Card: "Form raccolta dati​",
                label11Card: '“A Corporate Accounting and Reporting Standard” – Revised Edition, validato da RINA SERVICES S.p.A.',
                help: "Assistenza",

                //ASSISTENZA
                label1Assistenza: "Cos’è il Carbon Intelligence Tool?",
                label2Assistenza: "Il Carbon Intelligence Tool (CIT) è stato pensato per guidare le aziende che partecipano ad Open-es con l’obiettivo di aiutarle ad accelerare la transizione sostenibile tramite la misurazione delle proprie emissioni di CO2 (Scope 1&2) e l’identificazione di leve di decarbonizzazione. Il Tool è disegnato per essere uno strumento user-friendly, rigoroso nelle metodologie di calcolo e conformemente al ",
                label3Assistenza: "“A Corporate Accounting and Reporting Standard” – Revised Edition, come validato da RINA SERVICES S.p.A.",
				label4Assistenza: "Attualmente il modello di calcolo del Tool è stato implementato per poter misurare le emissioni per aziende appartenenti a quattro ambiti, di cui tre settoriali e uno generale. In particolare, gli ambiti sono: 1) Costruzioni ed Ingegneria; 2) Industriale; 3) Trasporti; 4) Energia e Utilities; 5) Chimico e Farmaceutico; 6) Beni di consumo e durevoli; 7) Terziario; 8) Altro. L’ambito di appartenenza è assegnato in funzione del settore dichiarato dall’azienda quando ha creato il profilo Open-es. Il Carbon Intelligence Tool implementato per l’ambito “Altro” è utilizzabile da tutte le aziende per cui non è disponibile un ambito settoriale specifico e consente di calcolare le emissioni di scope 1&2 dell’azienda.",
                label5Assistenza: "Come inserire i dati?",
                label6Assistenza: "Per ogni categoria di emissione occorre rispondere a tre diverse domande:",
                label7Assistenza: " - La specifica categoria di emissione fa parte delle attività dell’azienda",
                label8Assistenza: " - Sono disponibili le informazioni sulla specifica categoria di emissione? In caso di risposta negativa, il CIT ricorre alle medie di settore per la stima;",
                label9Assistenza: " - Viene richiesto di inserire le informazioni relative alle fonti emissive (i.e elenco dei combustibili/gas, paesi in cui si acquista energia elettrica). E’ possibile inserire le informazioni in diversi formati (dati di consumo, dati di spesa, emissioni equivalenti, percentuali) ed è fondamentale che ogni fonte emissiva venga inserita una volta sola: ad esempio se sono disponibili, per una fonte emissiva, le informazioni di consumo e quelle di spesa, vanno inserite solo quelle di consumo. Per ulteriori dettagli si consiglia di consultare il Manuale Utente.",
                label10Assistenza: "Indicazioni su come utilizzare il form di raccolta dati ",
                label11Assistenza: "E’ disponibile per gli utenti un excel di raccolta dati che ricalca la struttura del questionario. Gli utenti possono utilizzare questo file per raccogliere offline tutte le informazioni necessarie, velocizzando la compilazione sul portale.",
                label12Assistenza: "Link utili",
                label13Assistenza: "Per richiedere supporto contattare ",

                //final
                label1Final:"Ottimo lavoro, hai terminato la compilazione!",
                label2Final:"Visualizza",
               
                //Report
                label1Report: "OTTIMO LAVORO",
                label2Report: "Questa è la tua impronta Carbonica relativa al",
                label3Report: "Torna su Open-es e troverai riportate le informazioni di stima",
                review: "REVISIONA",
                label4Report: "LIVELLO DI ACCURACY",
                HIGH: "ALTA",
                HIGHEST: "MASSIMA",
                MEDIUM: "MEDIA",
                GOOD: "BUONA",
                LOW:"BASSO",
                average:"media",
                output:"la tua azienda",
                accuracy:"ACCURATEZZA",
                labelHigh: "Hai ottenuto un livello di accuracy alto grazie ad un ampio utilizzo di dati di consumo (es. litro, kWh) e al completamento di tutte le domande rilevanti. Ti consigliamo di mantenere alta la qualità del dato in input per garantire un calcolo puntuale delle emissioni",
                labelMedium: "Hai ottenuto un livello di accuracy medio in quanto sono state stimate le emissioni di alcune categorie e/o sono stati utilizzati dati di spesa. Ti consigliamo di inserire quanto più possibile dati di consumo (es. litri, kWh) per migliorare qualità del dato in input e quindi garantire un calcolo più accurato delle emissioni",
                labelLow: "Hai ottenuto un livello di accuracy basso in quanto non sono stati forniti i dati per le domande principali e/o non sono stati utilizzati dati di consumo (es. litri, kWh) ma informazioni relative alla spesa.Ti consigliamo di fornire i dati per le domande principali e inserire quanto più possibile dati di consumo (es. litri, kWh) per migliorare la qualità del dato in input e quindi garantire un calcolo più accurato delle emissioni",
                label5Report: "LA TUA CARBON FOOTPRINT",
                label6Report: "Dettaglio emissioni scope 1",
                label62Report: "Dettaglio emissioni scope 2",
                label7Report: "Combustione stazionaria",
                label72Report: "emissioni dirette gas serra da combustibili fossili in strutture aziendali.",
                label8Report: "Combustione mobile",
                label82Report: "emissioni dirette da veicoli aziendali.",
                label9Report: "Gas industriali",
                label92Report: "emissioni dirette da processi industriali con gas specifici.",
                label10Report: "Gas refrigeranti",
                label102Report: "emissioni dirette da sistemi di refrigerazione.",
                label11Report: "Energia elettrica",
                label112Report: "emissioni indirette da acquisto di energia elettrica.",
                label12Report: "Calore Raffreddamento",
                label122Report: "emissioni indirette da acquisto calore o raffreddamento.",
                label13Report: "Intensità carbonica per milione di € di fatturato (tCO2eq/M€) e confronto con media di settore*",
                label14Report: "*Il benchmark di settore deriva da statistiche relative al settore di appartenenza e non considera le caratteristiche specifiche dell'azienda in analisi",
                label19Report: "Accelera la transizione ecologica!",
                label20Report: "Le leve di decarbonizzazione proposte sono coerenti e applicabili per i valori di emissioni emersi e le attività aziendali eseguite. Sono quindi da intendersi come gli ambiti prioritari su cui intraprendere un percorso di decarbonizzazione, da configurare e allineare ad eventuali priorità già intraprese.",
                label21Report: "Per avere il dettaglio delle categorie emissive compilare tutte le sezioni del questionario di Scope 1 e migliorare l'accuratezza del risultato",
                label22Report: "Per avere il dettaglio delle categorie emissive compilare tutte le sezioni del questionario di Scope 2 e migliorare l'accuratezza del risultato",
                label23Report: "Ogni leva di decarbonizzazione ha un relativo TRL (Technology Readiness Level), il quale corrisponde​ al Livello di Maturità tecnologica dell'azione di decarbonizzazione (sono incluse azioni con livello di TRL 7 Dimostrazione pre-commerciale a livello 11 Prova di stabilità raggiunta), vedasi riferimenti ad",
                infoScope1ReportLine2: "-Mobili: emissioni dirette da veicoli aziendali.",
                infoScope1ReportLine3: "-Gas industriali: emissioni dirette da processi industriali con gas specifici.",
                infoScope1ReportLine4: "-Gas refrigeranti: emissioni dirette da sistemi di refrigerazione.",
                infoScope1ReportLine1: "-Stazionarie: emissioni dirette gas serra da combustibili fossili in strutture aziendali.",
                infoScope2ReportLine1: "-Energia elettrica: emissioni indirette da acquisto di energia elettrica.",
                infoScope2ReportLine2: "-Calore/raffreddamento: emissioni indirette da acquisto calore o raffreddamento.",
                openes: "Torna su open es",
                disclaimer : "L’applicativo “Carbon Intelligence Tool” è uno strumento reso disponibile da Eni S.p.A. a titolo gratuito per permettere agli Utenti la quantificazione delle emissioni di gas ad effetto serra dirette (Scope 1) ed indirette da acquisto di energia (Scope 2). RINA ha emesso una opinione di validazione sulla conformità al GHG PROTOCOL “A CORPORATE ACCOUNTING AND REPORTING STANDARD” – REVISED EDITION del Carbon Intelligence Tool ma non sugli output generati dall’applicativo che dipendono dalla correttezza dei dati inseriti. Gli output generati dall’applicativo sono basati sui dati immessi in maniera indipendente e autonoma dagli Utenti, che si assumono le relative responsabilità. ENI S.p.A. e i suoi fornitori che hanno lavorato alla realizzazione dell’applicativo non forniscono alcuna garanzia, esplicita o implicita, rispetto all’utilizzo dell’applicativo e, di conseguenza, degli output generati. ",
            }
        }
    }
});

export default i18n;