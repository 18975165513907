import React, { useEffect, useState } from "react";
import { Grid, Button, Paper, Autocomplete, Divider } from "@mui/material";
import { ThemeProvider } from '@emotion/react';
import { createMyTheme } from '../../styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { CustomTextField } from "../../styles/CustomTextField";
import * as client from '../../Integration/api';
import { ClipLoader } from 'react-spinners';
import { Configuration, SubClusterResponse } from '../../Integration';
import { ModalComponent } from "../Components/ModalComponent";
import { BsTypeH2 } from "react-icons/bs";



export const Assistenza: React.FC = ({ }) => {

    const theme = createMyTheme();
    const navigate = useNavigate();
    const location = useLocation()
    const [loadingManuale, setLoadingManuale] = useState<boolean>(false);
    const [loadingQuestionario, setLoadingQuestionario] = useState<boolean>(false);
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language)
    const token = sessionStorage.getItem("sessionToken")

    useEffect(() => {
        setCurrentLanguage(i18n.language)
    }, [i18n.language]);


    const handleContinue = () => {

        navigate(`/profile`)
    }

    const handleDownloadManuale = async () => {

        const conf: Configuration = {
            accessToken: token ? token : "",
            baseOptions: {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            },
        };
        const clientAPI = new client.DefaultApi(conf)

        setLoadingManuale(true)
        clientAPI.downloadManuale(currentLanguage).then(response => {
            const byteCharacters = atob(response.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = currentLanguage==="it" ? "CIT_manuale_utente" + ".pdf" :"CIT_user_manual" + ".pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(fileURL);
            setLoadingManuale(false)
        }).catch(error => {
            console.error(error)
        });

    }

    const handleDownloadQuestionario = async () => {

        const conf: Configuration = {
            accessToken: token ? token : "",
            baseOptions: {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            },
        };
        const clientAPI = new client.DefaultApi(conf)

        setLoadingQuestionario(true)
        clientAPI.downloadQuestionario(currentLanguage).then(response => {
            const byteCharacters = atob(response.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/vnd.ms-excel' });
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = currentLanguage==="it" ? "CIT_form_raccolta_dati" + ".xlsx" :"CIT_data_collection_form" + ".xlsx";
            //link.download = "form_dati" + ".xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(fileURL);
            setLoadingQuestionario(false)
        }).catch(error => {
            console.error(error)
        });

    }


    return (

        <ThemeProvider theme={theme}>

            <Grid
                container
                direction="column"
                display='flex'
                justifyContent="flex-start"
                alignItems="center"
                style={{ background: "#F5F5F5", minHeight: "93vh" }}

            >

                <>


                    <Grid item style={{ marginTop: "14vh", width: "80vw" }}>
                        <Paper
                            square
                            elevation={3}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: "center",
                                textAlign: "left",
                                pl: 2,
                                bgcolor: 'white',
                                gap:"-1vh",
                                fontStyle:"roboto"           
                            }}
                        >
                            <p style={{ color: "black", fontWeight: 'bold', textAlign: 'center', fontSize: '1vw' }}>
                                {t("help")}
                            </p>

                            <Divider style={{ width: "100%" }} />

                            <div>
                                <div>
                                <p style={{ fontWeight: "bold" }}><b>{t("label1Assistenza")}</b></p>
                                    <p>
                                        {t("label2Assistenza")} <a href='https://ghgprotocol.org/sites/default/files/standards/ghg-protocol-revised.pdf' target="_blank">GHG protocol</a> {t("label3Assistenza")}
                                    </p>
                                    <p>
                                        {t("label4Assistenza")}
                                    </p>
                                    <Divider style={{ width: "100%" }} />
                                    <p style={{ fontWeight: "bold" }}><b>{t("label5Assistenza")}</b></p>
                                    <p>
                                        {t("label6Assistenza")}
                                    </p>
                                    <p>
                                        {t("label7Assistenza")}
                                    </p>
                                    <p>
                                        {t("label8Assistenza")}
                                    </p>
                                    <p>
                                        {t("label9Assistenza")}
                                    </p>
                                    <Divider style={{ width: "100%" }} />
                                    <p style={{ fontWeight: "bold" }}><b>{t("label10Assistenza")}</b></p>
                                    <p>
                                        {t("label11Assistenza")}
                                    </p>
                                    <Divider style={{ width: "100%" }} />
                                    <p style={{ fontWeight: "bold" }}><b>{t("label12Assistenza")}</b>: <a href='https://ghgprotocol.org/sites/default/files/standards/ghg-protocol-revised.pdf' target="_blank">GHG protocol</a>, <a href='https://www.iea.org/data-and-statistics/data-tools/etp-clean-energy-technology-guide' target="_blank">IEA</a></p>
                                    <Divider style={{ width: "100%" }} />
                                    <p>{t("label13Assistenza")} <a href="mailto:info@openes.io">info@openes.io</a></p>                                    
                                </div>
                            </div>

                        </Paper>
                    </Grid>
                    <Grid item style={{width: "80vw", marginBottom: "5vh", marginTop: "2vh" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "1vw" }}>
                                <Button
                                    variant="outlined"
                                    onClick={e => handleDownloadManuale()}
                                    style={{
                                        color: "#015F9F",
                                        borderColor: "#FFD600",
                                        background: "#FFD600",
                                        fontWeight: 'bold',
                                        fontSize: '0.9vw',
                                        width: "12vw",
                                        height: "5vh"
                                    }}
                                >
                                    {loadingManuale ? (
                                        <ClipLoader color={'#ffffff'} size={20} loading={loadingManuale} />
                                    ) : (
                                        <p> {t("label9Card")}</p>
                                    )
                                    }

                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={e => handleDownloadQuestionario()}
                                    style={{
                                        color: "#015F9F",
                                        borderColor: "#FFD600",
                                        background: "#FFD600",
                                        fontWeight: 'bold',
                                        fontSize: '0.9vw',
                                        width: "12vw",
                                        height: "5vh"
                                    }}
                                >
                                    {loadingQuestionario ? (
                                        <ClipLoader color={'#ffffff'} size={20} loading={loadingQuestionario} />
                                    ) : (
                                        <p> {t("label10Card")}</p>
                                    )
                                    }

                                </Button>

                            </div>
                        </Grid>
                    </Grid>
                </>
            </Grid>
        </ThemeProvider>
    )
}